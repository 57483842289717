#dbwrapper{
    /* margin-top: 80px; */
    /* height: 100%; */
    height: 100vh;
    background:rgb(169, 197, 230);
    /* background-image: url("../../cryptobg.jpg"); */
    display: grid;
    grid-template-columns: 8fr 5fr;
}
#nocoinwrapper{
    /* margin-top: 80px; */
    /* height: 100%; */
    height: 100vh;
    background:rgb(169, 197, 230);
    /* background-image: url("../../cryptobg.jpg"); */
    display: grid;
    grid-template-rows: 4fr 6fr 2fr;
}
.gridiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
#welcome, #mesg{
    margin: 10px;
    align-self: center;
    text-align: center;
}
#addcoingif {
    margin: auto;
    /* width: 50%; */

    /* border: 5px solid #FFFF00; */
    /* padding: 10px; */
    /* align-self: center; */
    /* object-fit: contain; */
}
#gifdiv{
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
}