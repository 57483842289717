
html,body {
    margin: 0;
    padding: 0;
}
#maindiv {
    margin-top: 80px;
    position: absolute;
    /* overflow: scroll; */
    /* background: red; */
    background-image: url("../../cryptobg.jpg");
    height: 100 vh;
    /* height: 100%; */
    /* width: 100%; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right; */
    /* display: grid; */
    /* grid-template-columns: 60% 40%; */
    /* grid-gap: 1em; */
}
#contentdiv{
    height: 100vh;
    position: relative;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: right; */
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-gap: 1em;
    justify-items: center;
    /* background: #1BC74E; */
}
#contentdiv > div{
    /* display: flex; */
    /* background: #eee; */
    padding:1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* background: #1BC74E; */
}
#contentdiv > div > *{
    margin-bottom: 10px
    /* margin-top: 0px */
}
#subtitle {
    color: rgba(255, 255, 255, 0.603);
    font-size: 18px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}
.gif{
    height: 100px;
    width: 100px;
    align-self: center;
}
#slogan {
    color: white;

}

#join_now{
  display: inline-block;
  height: 40px;
  padding: 0px 25px;
  background: #1BC74E;
  border: none;
  font: 14px 'Varela Round';
  color: #fff;
  border-radius: 100px;
  cursor: pointer;
}
#maintitle {
  color: #fff;
  font-size: 36px;
}