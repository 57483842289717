* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

/* .App{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
} */
