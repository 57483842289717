html,body {
    margin: 0;
    padding: 0;
}

#coinwrapper{
    /* height: 100%; */
    height: 100vh;
    /* background: chocolate; */
    /* margin-top: 20px; */
    /* padding: 3em; */
    /* padding-top: 10%; */
    margin-top: 80px;
    padding-left: 40px;
    background-image: url("../../cryptobg.jpg");
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-gap: 1em;
    justify-items: center;
    /* margin: 100px; */
    /* padding: 100px; */
    /* display: inline-block; */
}
#coinwrapper >div{
    /* border-style: dashed; */
    /* z-index: 5000; */
}
#notif .notification-container {
    top: 100px;
    right: 25%;
}
#notif .notification-info {
    background-color: #2f96b4;
    /* background-color:chartreuse; */
  
  }
/* #notif{
    position: absolute;
    margin-top: 80px;
    color: chartreuse;
} */
h2 {
  margin-top: 0 !important;
}

#crypto-table {
    margin-top: 10%;
}

/* ul {
    margin-top: 50px;
} */

.active {
    background-color: #ffa726 !important;
}

.bold {
    font-weight: 600;
}

.Coins{
    /* position: absolute; */
    /* background: chartreuse; */
    /* width: 50%; */
    display: flex;
    justify-content: center;
    flex-direction: column;    
    align-items: center;
    margin: 5px;
}
.field {
    width: 50%;
    height: 40px;
    border-radius: 4px;
    /* position: relative; */
    background-color: rgba(255,255,255,0.3);
    transition: 0.3s all;
    margin: 10px;
    text-indent: 10px;
  }
  .field:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
.tc{
    margin: 30px;
}